<template>
  <div>
    <b-card title="" header-tag="header" class="card-custom">
      <template v-slot:header>
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ title }}</h3>
        </div>
        <div class="card-toolbar"></div>
      </template>
      <div style="max-width: 800px">
        <form class="form" novalidate="novalidate" id="product_basic">
          <label class="fw-600">Offer Image</label>
          <div class="form-group row">
            <div class="col-lg-9 col-xl-9">
              <div class="image-input image-input-outline" id="kt_user_add_avatar">
                <div
                  class="image-input-wrapper"
                  :style="{
                    backgroundImage: `url(${
                      previewImage != null ? previewImage : imgPath + form.image
                    })`,
                  }"
                ></div>
                <label
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i class="fa fa-pen icon-sm text-muted"></i>
                  <input
                    type="file"
                    ref="file"
                    @change="selectImage"
                    name="profile_avatar"
                    accept=".png, .jpg, .jpeg"
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>
                <span
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Cancel avatar"
                >
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
            </div>
          </div>
          <b-form-group label-for="input-1">
            <label class="fw-600"
              >Offer Tittle <sup class="text-danger font-weight-boldest">*</sup></label
            >
            <b-form-input
              v-model="form.title"
              type="text"
              name="title"
              class="form-control"
              placeholder="EG: Shape"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label-for="input-1">
            <label class="fw-600">Offer Slug</label>
            <b-form-input
              v-model="form.page_key"
              type="text"
              name="page_key"
              class="form-control"
              placeholder="EG: Shape"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label-for="input-1">
            <label>Banner Class</label>
            <b-form-input
              v-model="form.banner_class"
              type="text"
              name="banner_class"
              class="form-control"
              placeholder="EG: Shape"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group label-for="input-1">
            <label class="fw-600"
              >Description <sup class="text-danger font-weight-boldest">*</sup></label
            >
            <Editor
              v-model="form.description"
              name="description"
              editorStyle="height: 100px"
            />
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group label="Type" class="fw-600">
                <b-form-select v-model="form.type" name="type" :options="types">
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Discount Type" class="fw-600">
                <b-form-select
                  v-model="form.discount_type"
                  name="discount_type"
                  :options="discountTypes"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Priority" class="fw-600">
                <b-form-select v-model="form.priority" name="type" :options="priorities">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Offer" class="fw-600">
                <b-form-input
                  min="0"
                  v-model="form.offer"
                  type="number"
                  name="offer"
                  class="form-control"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="form.type == 'both_with_different_value'">
              <b-form-group label="Lense Offer" class="fw-600">
                <b-form-input
                  min="0"
                  v-model="form.lense_offer"
                  type="number"
                  name="lense_offer"
                  class="form-control"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Maximum discount amount" class="fw-600">
                <b-form-input
                  min="0"
                  v-model="form.maximum_amount_discount"
                  type="number"
                  name="maximum_amount_discount"
                  class="form-control"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label class="fw-600">Start Date</label>
                <div class="position-relative">
                  <Calendar
                    v-model="form.offer_from"
                    dateFormat="yy-mm-dd"
                    class="w-100"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label class="fw-600">End Date</label>
                <div class="position-relative">
                  <Calendar v-model="form.offer_to" dateFormat="yy-mm-dd" class="w-100" />
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="input-1">
                <label class="fw-600"
                  >Coupon Applicable
                  <sup class="text-danger font-weight-boldest">*</sup></label
                >
                <b-form-select v-model="form.coupon_applicable">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="input-1">
                <label class="fw-600"
                  >Status <sup class="text-danger font-weight-boldest">*</sup></label
                >
                <b-form-select v-model="form.status">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <button
                ref="applyBtn"
                type="button"
                v-if="form.apply_status == 'no' && form.id != null"
                class="btn btn-primary mr-5"
                @click="applyStatusUpdate('yes')"
              >
                <i class="las la-save"></i> Apply
              </button>
              <button
                ref="applyBtn"
                type="button"
                v-else-if="form.id != null"
                class="btn btn-primary mr-5"
                @click="applyStatusUpdate('no')"
              >
                <i class="las la-save"></i> Stop Offer
              </button>
              <button ref="kt_login_signin_submit" class="btn btn-primary">
                <i class="las la-save"></i> Save
              </button>
            </b-col>
          </b-row>
          <hr />
          <div v-if="form.id">
            <div class="d-flex justify-content-between pb-3 align-items-center"></div>
            <div class="d-flex justify-content-between align-items-center ml-3 pb-3">
              <!-- <b-form-checkbox value="me" size="lg" class="font-weight-bold"
            >Select All</b-form-checkbox
          > -->
              <h5 class="m-0 p-0 fw-600">Offer Applied Products</h5>
              <!-- <div class="d-flex align-items-center">
              <div class="input-group mb-0 mr-3 pl-0" style="width: 250px">
                <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="las la-search"></i>
                </div>
              </div>
              <input
                type="search"
                autocomplete="off"
                class="form-control"
                placeholder="Search Products"
              />
              </div>
            </div> -->
              <div>
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-2"
                  v-if="selectedProducts.length != 0"
                  @click="deleteSelectedProducts"
                  ><i class="las la-trash mr-2"></i>Delete Selected</b-button
                >

                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-2"
                  v-else-if="form.offer_products.length != 0"
                  @click="deleteAll"
                  ><i class="las la-trash mr-2"></i>Delete ALL</b-button
                >

                <b-button
                  variant="primary"
                  size="sm"
                  v-if="form.id"
                  class="mr-2"
                  :to="`/offer-product-add/${form.id}`"
                  ><i class="las la-plus mr-2"></i>Add Product</b-button
                >
              </div>
            </div>

            <div class="border offer-list mb-4" v-if="form.offer_products.length != 0">
              <perfect-scrollbar
                class="aside-menu scroll bg-white"
                style="max-height: 250px; position: relative"
              >
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selectedProducts"
                  name="flavour-2"
                >
                  <div
                    class="item d-flex row mx-0"
                    v-for="product in form.offer_products"
                    :key="`selectedProduct-${product.id}`"
                  >
                    <div class="border-right pl-3 pt-2" style="width: 40px">
                      <b-form-checkbox :value="product.id" size="lg"></b-form-checkbox>
                    </div>
                    <div class="border-right pl-" style="width: 80px">
                      <div class="p-2">
                        <img
                          v-if="product.images == null"
                          src="@/custom/media/dummy/frame.jpg"
                          width="50"
                        />
                        <img
                          v-else
                          :src="imgPath + product.images.image"
                          alt="..."
                          width="50"
                        />
                      </div>
                    </div>
                    <div class="col-4 border-right">
                      <div class="p-2 pt-3">{{ product.name }}</div>
                    </div>
                    <div class="col-3 border-right" style="max-width: 150px">
                      <div class="p-2 pt-3">{{ product.model }}</div>
                    </div>
                    <div class="col-auto ml-auto">
                      <div class="p-2 pb-0 text-right">
                        <b-button
                          class="btn-icon p-1 w-auto h-auto"
                          @click="deleteProduct(product.id)"
                          ><i class="las la-trash-alt"></i
                        ></b-button>
                      </div>
                    </div>
                  </div>
                </b-form-checkbox-group>
              </perfect-scrollbar>
            </div>
            <div v-else class="text-center py-5">
              <img src="@/custom/media/svg/empthy-cart.svg" width="100" />
              <div>No products added</div>
            </div>
          </div>
        </form>
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Editor from "primevue/editor";
import Calendar from "primevue/calendar";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import OfferService from "@/core/services/api/offer";
import CategoryService from "@/core/services/api/category";

import Swal from "sweetalert2";
export default {
  components: { Editor, Calendar },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      currentImage: null,
      previewImage: null,
      title: this.$route.params.id ? "Update Offer" : "New offer",
      discountTypes: [
        { value: "percentage", text: "Percentage" },
        { value: "fixed", text: "Fixed" },
      ],
      types: [
        { value: "frame", text: "Frame" },
        { value: "lense", text: "Lense" },
        { value: "both", text: "Both" },
        { value: "both_with_different_value", text: "Both with different value" },
      ],
      priorities: [
        { value: "1", text: "Low" },
        { value: "2", text: "Medium" },
        { value: "3", text: "High" },
        { value: "4", text: "Very High" },
      ],
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      form: {
        id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
        title: "",
        banner_class: "",
        page_key: "",
        image: "",
        type: "frame",
        offer: 0,
        lense_offer: 0,
        maximum_amount_discount: 0,
        offer_to: "0000-00-00",
        offer_from: this.moment().format("Y-MM-D"),
        priority: 1,
        description: "",
        status: "active",
        coupon_applicable: "no",
        offer_products: [],
        apply_status: "no",
        discount_type: "fixed",
      },
      selectedProducts: [],
    };
  },
  mounted() {
    var vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Offers",
        route: "/offers",
      },
      {
        title: "Offer : New",
      },
    ]);
    const signin_form = KTUtil.getById("product_basic");
    this.fv = formValidation(signin_form, {
      fields: {
        title: {
          validators: {
            notEmpty: {
              message: "Title is required",
            },
          },
        },
        page_key: {
          validators: {
            notEmpty: {
              message: "Slug is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", async () => {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");

      const formData = new FormData();
      formData.append("offer", JSON.stringify(vm.form));
      formData.append("image", vm.currentImage);
      if (this.id == null) {
        await this.createOffer(formData, function (response) {
          vm.response(response, submitButton);
        });
      } else {
        await this.updateOffer(formData, function (response) {
          vm.response(response, submitButton);
        });
      }
    });

    if (this.id != null) {
      this.getOfferById();
    }
  },
  methods: {
    applyStatusUpdate(status) {
      var vm = this;
      const submitButton = this.$refs["applyBtn"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");
      const formData = new FormData();
      formData.append("apply", status);
      OfferService.applyOffer(vm.form.id, formData)
        .then(function (response) {
          vm.form.apply_status = status;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        })
        .catch((err) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    },
    response(response, submitButton) {
      var vm = this;
      if (response) {
        let timerInterval;
        Swal.fire({
          title: response.message,
          timer: 500,
          icon: "success",
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          vm.$router.push({ name: "offers" });
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    },
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
    },
    async getOfferById() {
      var response = await OfferService.getOfferById(this.id);
      this.form = {
        id: response.id,
        title: response.title,
        page_key: response.page_key,
        image: response.image,
        type: response.type,
        offer: response.offer,
        banner_class: response.banner_class,
        lense_offer: response.lense_offer,
        maximum_amount_discount: response.maximum_amount_discount,
        offer_to: response.offer_to,
        offer_from: response.offer_from,
        priority: response.priority,
        description: response.description,
        status: response.status,
        coupon_applicable: response.coupon_applicable,
        offer_products: response.offer_products,
        apply_status: response.apply_status,
        discount_type: response.discount_type,
      };
    },
    createOffer(formData, callback) {
      OfferService.createOffer(formData)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
    updateOffer(formData, callback) {
      var vm = this;
      OfferService.updateOffer(formData, this.id)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
    deleteAll() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          OfferService.deleteAllProducts(this.form.id).then(() => {
            Swal.fire("Deleted!", "All products has been deleted.", "success");
            this.getOfferById(this.form.id);
          });
        }
      });
    },
    deleteSelectedProducts() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          OfferService.deleteSelectedProducts(
            this.form.id,
            this.selectedProducts.join("~")
          ).then(() => {
            Swal.fire("Deleted!", "Selected products has been deleted.", "success");
            this.getOfferById(this.form.id);
            this.selectedProducts = [];
          });
        }
      });
    },
    deleteProduct(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          OfferService.deleteProduct(this.form.id, id).then(() => {
            Swal.fire("Deleted!", "One product has been deleted.", "success");
            this.getOfferById(this.form.id);
          });
        }
      });
    },
  },
};
</script>

<style></style>
